<template>
	<div>
		<div>
			<el-form :inline="true" ref="queryForm" :model="queryForm">
				<el-form-item>
					<el-input v-model="queryForm.name" placeholder="按名称查询"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" @click="list" type="danger">查询</el-button>
					<el-button icon="el-icon-plus" @click="add" type="primary">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData">
			<el-table-column prop="name" label="栏目名称" min-width="200"></el-table-column>
			<el-table-column prop="type" label="栏目类型" min-width="200"></el-table-column>
			<el-table-column prop="position" label="栏目位置" min-width="200"></el-table-column>
			<el-table-column prop="single1" label="是否单页" min-width="200"></el-table-column>
			<el-table-column align="center" label="操作" min-width="250">
				<template slot-scope="scope">
					<el-link @click="edit(scope.row)" :underline="false" type="primary" class="el-icon-edit-outline">编辑</el-link>
					<span style="display: inline-block;width: 20px;"></span>
					<el-link @click="del(scope.row)" :underline="false" type="danger" class="el-icon-delete">删除</el-link>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination background layout="total,prev, pager, next,jumper" @current-change="handleCurrentChange"
			:current-page="pageNo" :total="total">
		</el-pagination>
	</div>
</template>

<script>
	import {del,query} from "@/api/channel";
	import token from '@/token.js'
	export default {
		name: "Index",
		data() {
			return {
				queryForm: {
					name: '',
				},
				tableData: [],
				total: 0,
				pageNo: 1,
				defForm: {
					id: 0,
					name: '',
					parentId: null,
					image: '',
					summary: '',
					single: false,
					type: '',
					position: '',
					content: '',
					updateUserID: token.getUser().id,
					updateUserName: token.getUser().manager_username
				},
			}
		},
		mounted() {
			this.list()
		},
		methods: {
			//改变当前页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.list()
			},
			//查询栏目列表
			list() {
				let param = {
					name: this.queryForm.name,
					page: this.pageNo,
					pageSize: 10,
				}
				query(param).then(data => {
					this.tableData = data.data.list
					this.total = data.data.total
					for (let i = 0; i < this.tableData.length; i++) {
						if (this.tableData[i].single == 'Y') {
							this.tableData[i].single1 = '是'
							this.tableData[i].single = true
						}
						if (this.tableData[i].single == 'N') {
							this.tableData[i].single1 = '否'
							this.tableData[i].single = false
						}
					}
					// console.log("列表",this.tableData)
				}).catch(error => {
					this.$message.error(error)
				})
			},
			//新增栏目信息
			add() {
				this.$router.push('/admin/content/channel_add')
			},
			//编辑栏目信息
			edit(row) {
				this.$router.push({
					path: '/admin/content/channel_edit',
					query: {
						channelInfo: row
					}
				})
			},
			del(row) {
				this.defForm.id=row.id,
				this.defForm.name=row.name,
				this.defForm.parentId=row.parentId,
				this.defForm.image=row.image,
				this.defForm.summary=row.summary,
				this.defForm.single=row.single,
				this.defForm.type=row.type,
				this.defForm.position=row.position,
				this.defForm.content=row.content==null ? '': row.content,

				this.$confirm('确定要删除吗？', "提示", {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					del(this.defForm).then(data => {
						let totalPage = Math.ceil((this.total - 1) / 10); //15 是每页15条
						let pageNo = this.pageNo > totalPage ? totalPage : this.pageNo;
						this.pageNo = pageNo < 1 ? 1 : pageNo; //this.pageNo 当前页数
						this.list()
						this.$message.success("栏目[" + row.name + "]删除成功!")
					}).catch(error => {
						this.$message.error(error)
					})
				}).catch(() => {

				})
			},
		}
	}
</script>

<style scoped>

</style>
